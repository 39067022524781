

import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";

import * as s from './GlossaryItems.module.css';

const GlossaryItems = ({ letters }) => {

  return (
    <div className={s.block}>
      {letters?.map((l, i) => {
        return <div id={`${l.letter}`} className={s.letter}>
          <h3>{l.letter}</h3>
          {l?.items?.map((item, j) => {
            return <div key={j} className={s.item}>
              {!!item?.url
                ? <Link className={s.title} to={item?.url} target="_blank">{parse(item?.word)}</Link>
                : <div className={s.title}>{parse(item?.word)}</div>
                }
              <div className={s.explanation}>{parse(item?.explanation)}</div>
            </div>
          })}
        </div>
      })}
    </div>
  )
}
export default GlossaryItems
