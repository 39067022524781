import { graphql } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from "react";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";
import schemaBuilder, { getLocalBusinessSchema } from "../utils/schemaBuilder";
import GlossaryItems from './../components/REDESIGN/GlossaryItems/GlossaryItems';
import GlossaryNav from './../components/REDESIGN/GlossaryNav/GlossaryNav';
import HeroAltBlock from './../components/REDESIGN/HeroAltBlock/HeroAltBlock';
import NewsletterSubscribeFooterBannerNew from './../components/REDESIGN/NewsletterSubscribeFooterBannerNew/NewsletterSubscribeFooterBannerNew';
import PostResourceChapters from './../components/REDESIGN/PostResourceChapters/PostResourceChapters';
import Footer from "./../components/REDESIGN/footer/footer";
import Header from './../components/REDESIGN/header/header';
import Seo from "./../components/seo";

const GlossaryPageTemplate = ({ data: { page, author, site }, location }) => {
  const bp = useBreakpoint();

  const schema = schemaBuilder({ page: page, author: author }, 'page-redesigned', site);

  if (page.uri == '/') {
    schema.push(getLocalBusinessSchema(site));
  };

  const letters = [
    { letter: 'a' },
    { letter: 'b' },
    { letter: 'c' },
    { letter: 'd' },
    { letter: 'e' },
    { letter: 'f' },
    { letter: 'g' },
    { letter: 'h' },
    { letter: 'i' },
    { letter: 'j' },
    { letter: 'k' },
    { letter: 'l' },
    { letter: 'm' },
    { letter: 'n' },
    { letter: 'o' },
    { letter: 'p' },
    { letter: 'q' },
    { letter: 'r' },
    { letter: 's' },
    { letter: 't' },
    { letter: 'u' },
    { letter: 'v' },
    { letter: 'w' },
    { letter: 'x' },
    { letter: 'y' },
    { letter: 'z' },
  ];

  return (
    <>
      <Header />
      <Seo
        title={page.title}
        description={""}
        pageSeo={page}
        location={location}
        schema={schema}
      />
      <HeroAltBlock block={page?.glossary?.heroBlock} />
      <GlossaryNav letters={letters} />
      <div className="container py-4 py-xl-5 my-xl-2">
        <div className="row">
          {(true == bp?.xxl && !!page?.glossary?.resourcePage) &&
            <div className="col-xxl-4 ">
              <div className="d-flex flex-column pe-xxl-5 pe-2 ps-1 pb-3 aga-scrollbar" style={{
                position: 'sticky',
                top: '182px',
                gap: '32px',
                maxHeight: 'calc(100vh - 120px - 72px)',
                zIndex: 20,
                overflow: 'auto',
              }}>
                <PostResourceChapters page={page?.glossary?.resourcePage} currentUri={''} />
              </div>
            </div>
          }
          <div className={`col-12 col-xxl-8`}>
            {(false == bp?.xxl && !!page?.glossary?.resourcePage) &&
              <div className="mb-4">
                <PostResourceChapters page={page?.glossary?.resourcePage} currentUri={''} />
              </div>
            }
            <GlossaryItems letters={page?.glossary?.letters} />
          </div>
        </div>
      </div>
      <NewsletterSubscribeFooterBannerNew pageTitle={page.title} />
      <Footer />
    </>
  )
}

export default GlossaryPageTemplate

export const pageQuery = graphql`
  query GlossaryPageById($id: String!, $authorId: String!) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    page: wpPage(id: {eq: $id}) {
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      glossary {
        heroBlock {
          title
          description
          button {
            url
            title
            target
          }
          rightImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        letters {
          letter
          items {
            word
            url
            explanation
          }
        }
        resourcePage {
          ... on WpPage {
            title
            contentRedesigned {
              contentRedesigned {
                ... on WpPage_Contentredesigned_ContentRedesigned_ResourceChaptersBlock {
                  __typename
                  chapters {
                    title
                    bottomPart {
                      parts {
                        link {
                          url
                          title
                          target
                        }
                      }
                    }
                  }
                  glossary {
                    link {
                      title
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      users {
        avatar {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`