
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import CustomLink from "../CustomLink/CustomLink";
import * as s from "./HeroAltBlock.module.css";

const HeroAltBlock = ({ block }) => {
  const bp = useBreakpoint();

  const rightImage = {
    data: block.rightImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.rightImage?.altText || ``,
  };

  return (
    <>
      <section className={`${s.block} position-relative`} id={block?.blockId}>
        {false == bp.md && <>
          <StaticImage
            src="./bg-mobile.png"
            alt="bg"
            placeholder="transparent"
            className="w-100 h-100 position-absolute"
            style={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </>}
        <div className={`container position-relative`}>
          <div className="row align-items-center">
            <div className="col-xxl-8">
              <div className={s.data}>
                <h1 className={`${s.title}`}>{parse(block.title)}</h1>
                {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
                <div className={`w-100 d-flex justify-content-center justify-content-md-start`}>
                  <CustomLink button={block?.button} className={''} />
                </div>
              </div>
            </div>
            {true == bp.xxl && <div className="col-xxl-4 d-none d-xxl-block">
              {(!!rightImage?.data) &&
                <GatsbyImage
                  className={'mw-100'}
                  loading="eager"
                  placeholder="transparent"
                  image={rightImage.data}
                  alt={rightImage.alt} />
              }
            </div>}
          </div>
        </div>
      </section>
      {!!block?.bottomContent && <section className={`${s.bottomContent}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {parse(block?.bottomContent)}
            </div>
          </div>
        </div>
      </section>}
    </>
  );
};



export default HeroAltBlock;
