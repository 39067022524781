import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CustomLink.module.css";

const CustomLink = ({ button, className }) => {
  return (
    <>
      {!!button?.url &&
        <Link
          to={button?.url}
          target={button?.target}
          className={`${s.button} ${className}`}
        >
          {parse(button?.title)}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.3443 6.39258H16.0011C16.5534 6.39258 17.0011 6.84029 17.0011 7.39258V12.0494M16.294 7.09969L6.39453 16.9992" stroke="#063B6D" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </Link>
      }
    </>
  );
};

export default CustomLink;
