

import React from "react";
import { Link } from 'gatsby';

import * as s from './GlossaryNav.module.css';


const GlossaryNav = ({ letters }) => {

  return (
    <>
      <section className={s.block}>
        <div className="container">
          <div className={`aga-scrollbar ${s.lettersWrapper}`}>
            <div className={s.letters}>
              {letters?.map((l, i) => {
                return <Link key={i} to={`#${l.letter}`}>{l.letter}</Link>
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default GlossaryNav
