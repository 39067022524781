

import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./PostResourceChapters.module.css";

const PostResourceChapters = ({ page, currentUri }) => {

  const [opened, setOpened] = React.useState(true);


  const toggle = () => {
    setOpened(!opened);
  }

  if (!page) {
    return null
  }

  return (
    <>
      <div className={`${s.resourceChapters}`}>
        <h5 onClick={toggle}>
          {parse(page?.title)}
          <div className={`${s.arrow}`} >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="11" viewBox="0 0 24 11" fill="none">
              <path d="M1.92969 2.00156L10.4011 9.26279C11.2999 10.0332 12.6261 10.0332 13.5249 9.26279L21.9964 2.00156" stroke="#063B6D" strokeWidth="2.2" strokeLinecap="round" />
            </svg>
          </div>
        </h5>
        <div className={`${s.links} ${opened ? '' : 'd-none'}`}>
          {!!page.contentRedesigned.contentRedesigned &&
            page.contentRedesigned.contentRedesigned.map((block, index) => {
              switch (block.__typename) {
                case 'WpPage_Contentredesigned_ContentRedesigned_ResourceChaptersBlock':
                  return <div key={index}>
                    {block?.chapters?.length > 0 && <>
                      {block?.chapters?.map((chapter, i) => {
                        return <div className="col-12" key={i}>
                          <Chapter chapter={chapter} index={i + 1} currentUri={currentUri} />
                        </div>
                      })}
                    </>}
                    {!!block?.glossary?.link?.url && <div className="col-12" >
                      <Glossary chapter={block?.glossary} />
                    </div>}
                  </div>
                  break;
                default:
                  return null
              }
            })
          }
        </div>
      </div>
    </>
  );
};


const Chapter = ({ chapter, index, currentUri }) => {

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    chapter?.bottomPart?.parts?.forEach((part, i) => {
      if (currentUri == part?.link?.url) {
        setOpen(true);
      }
    })
  }, [chapter])

  return <div className={`${s.chapter} ${open ? s.open : ''}`}>
    <div className="row">
      <div className={`col-12`}>
        {!!chapter?.title && <div className={s.title} onClick={() => {setOpen(!open)}}>{!!index && <>{index}. </>}{parse(chapter?.title)}</div>}
      </div>
      {!!chapter?.bottomPart?.parts?.length > 0 && <div className={`col-12 ${s.bottomPartWrapper}`}>
        <ul>
          {chapter?.bottomPart?.parts?.map((part, i) => {
            if (!part?.link?.url) {
              return null;
            }
            return <li key={i}><Link activeClassName={s.current} to={part?.link?.url} target={part?.link?.target}>{part?.link?.title}</Link></li>
          })}
        </ul>
      </div>}
    </div>
  </div>
}


const Glossary = ({ chapter }) => {
  return <div className={s.chapter}>
    <div className="row">
      <div className={`col-12`}>
        {!!chapter?.link?.url && <Link className={s.glossary} to={chapter?.link?.url} target={chapter?.link?.target}>{parse(chapter?.link?.title)}</Link>}
      </div>
    </div>
  </div>
}

export default PostResourceChapters;
